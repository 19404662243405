@import 'reset';
@import 'variables';
@import 'mixins';
@import 'utilities';
@import 'fonts';

// Components
@import 'components/app-buttons';
@import 'components/banner';
@import 'components/buttons';
@import 'components/form';
@import 'components/footer';
@import 'components/grid';
@import 'components/navbar';
@import 'components/page-header';
@import 'components/pal-experience';
// @import "components/papa-programs";
@import 'components/portal';
@import 'components/flow-components';
@import 'components/faq';
@import 'components/password-meter';

// Pages
@import 'pages/become-a-pal';
@import 'pages/form-page';
@import 'pages/app-flow';
@import 'pages/visit-rating';

@import 'containers/PalOnboarding/Agreements';

html,
body,
#root,
#app {
  min-height: 100vh;
}

#app {
  display: flex;
  flex-direction: column;
}

#app__content {
  flex: 1 0 auto;
}

.app-padding {
  padding-top: 100px;
  @include media-breakpoint(min, md) {
    padding-top: 140px;
  }
}

body {
  color: $slate;
  font-family: $inter;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin-top: 0;
}

h2,
h3,
h4,
h5 {
  font-family: $avenir;
  font-weight: 700;
}

h4 {
  margin-bottom: 0;
}

.font-600 {
  font-weight: 600;
}

#app_content h4 {
  font-size: 24px;
  line-height: 33px;
  letter-spacing: -0.71px;
}

hr {
  margin: $grid-gutter auto;
  border: 0;
  border-top: 3px solid $slate;
}

a {
  text-decoration: none;
}

ul {
  margin-top: 0;
}

img {
  display: block;
}

.img-responsive {
  max-width: 100%;
  height: auto;
}

.img-circle {
  border-radius: 50%;
}

.sr-only {
  position: absolute !important;
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  margin: -1px !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important;
}

.section-headings {
  h5 {
    font-size: 18px;
    line-height: 24px;
    @include media-breakpoint(min, md) {
      font-size: 24px;
      line-height: 36px;
    }
  }

  h3 {
    font-size: 42px;
    font-weight: 700;
    line-height: 48px;
    letter-spacing: -0.62px;

    span {
      color: $red;
    }
  }

  h4 {
    font-size: 35px;
    line-height: 48px;
    letter-spacing: -0.48px;

    span {
      color: $red;
    }
  }

  p {
    font-size: 18px;
    line-height: 25px;
    @include media-breakpoint(min, md) {
      font-size: 22px;
      line-height: 33px;
    }
  }
}

.player-wrapper {
  width: 100%;
  position: relative;
  // padding-top: 56.25%; /* Player ratio: 100 / (1280 / 720) */

  .react-player {
    position: absolute;
    top: 0;
    left: 0;
  }
}

.programs-with-papa {
  h3 {
    @include media-breakpoint(max, md) {
      font-size: 28px;
      line-height: 34px;
    }
  }
}

.page-404 {
  display: flex;
  flex-direction: column;

  .page-header {
    flex: 1;

    > .container {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    .papa-head {
      margin-bottom: 60px;

      span {
        font-size: 120px;
        font-family: $wavehaus;
        @include media-breakpoint(min, md) {
          font-size: 290px;
        }
      }

      img {
        max-width: 82px;
        margin: 0 10px 0 12px;
        @include media-breakpoint(min, md) {
          max-width: 100%;
          margin: 0 10px 0 20px;
        }
      }
    }

    .button {
      max-width: 100%;
      width: 400px;
    }

    h1 {
      margin-bottom: 30px;
    }

    h2 {
      margin-bottom: 50px;
    }
  }
}

.stripe-success-page-content {
  display: flex;
  flex-direction: column;

  .page-header {
    flex: 1;

    > .container {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    .button {
      max-width: 100%;
      width: 400px;
    }

    h1 {
      margin-bottom: 1.875em;
    }

    h2 {
      margin-bottom: 3.125em;
      color: #284d70;
    }

    p {
      color: #284d70;
    }
  }

  .success-message {
    margin: 4em auto;
    padding: 3em;
    background: rgba(255, 255, 255, 0.8);
    border-radius: 20px;

    @media (max-height: 937px) {
      margin-top: 12em;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 2em;
    }
  }
}

.thank-you {
  display: flex;
  flex-direction: column;

  .page-header {
    flex: 1;

    > .container {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    .papa-head {
      margin-bottom: 60px;
      justify-content: center;

      span {
        font-size: 120px;
        font-family: $wavehaus;
        @include media-breakpoint(min, md) {
          font-size: 220px;
        }
      }

      img {
        max-width: 70px;
        margin: 0 10px 0 12px;
        align-self: flex-end;
        margin: 0 0 20px -10px;
        @include media-breakpoint(min, md) {
          height: 130px;
          max-width: 100%;
          margin: 0 10px 40px -20px;
        }
      }
    }

    .button {
      max-width: 100%;
      width: 400px;
    }

    h1 {
      margin-bottom: 30px;
    }

    h2 {
      margin-bottom: 50px;
    }
  }
}

.page-header--pal-onboarding {
  padding: 0;
}

.app-flow {
  min-height: 100vh;

  &--landing {
    font-family: $avenir;
    min-height: auto;
    padding-top: 100px;
    @include media-breakpoint(min, md) {
      padding-top: 140px;
    }

    .pal-experience-row img {
      transform: translateX(0);
    }

    .become-a-pal .benefits .section-headings h3 {
      text-align: center !important;
    }

    .what-is-papa {
      h3 {
        font-size: 42px;
        font-weight: 700;
        margin-bottom: 24px !important;
      }

      p {
        font-size: 20px;
        line-height: 1.5em;
        max-width: 720px;
        margin: 0 auto 100px;
      }
    }

    .alert-banner {
      background: #ec6738;
      // margin-bottom: 30px;
      // @include media-breakpoint(min, md) {
      //   margin-bottom: 70px;
      // }

      a {
        display: block;
        color: $white;
        font-size: 16px;
        line-height: 1.5;
        padding: 13px 0;
        margin: 0;
      }
    }

    .apply-now-section {
      color: $white;
      background: $blue;
      padding: 0 0 80px;
      @include media-breakpoint(min, md) {
        display: none;
      }

      &--with-banner {
        padding-bottom: 180px;
      }

      .flow-button {
        color: $white;
        background-color: #00a56c;
        border: 0;
      }
    }
  }

  &__main {
    width: 100%;
    background: url('../../assets/wave.svg');
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: left bottom;
    padding-left: 32px;
    padding-right: 32px;
    padding-bottom: 180px;
    padding-top: 32px;
    @include media-breakpoint(min, md) {
      width: 560px;
      padding-top: 192px;
      padding-left: 100px;
      padding-right: 100px;
    }

    &--landing {
      width: 100%;
      background-color: $primary-default;
      padding-bottom: 60px;
      position: relative;
      @include media-breakpoint(min, md) {
        background-image: url('../../assets/palOnboarding/onboarding-bg.png');
        background-size: cover;
        background-position: right 0 center;
        padding-bottom: 100px;
        padding-top: 100px;
      }

      &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: linear-gradient(
          90deg,
          rgba(38, 38, 69, 0.37) 25.76%,
          rgba(38, 38, 69, 0) 57.63%
        );
        background-blend-mode: multiply;
      }

      .text {
        color: $white;
      }

      .flow-form-input {
        background-color: $white;
      }
    }
  }

  .landingText {
    a {
      color: $white;
      text-decoration: underline;
    }
  }

  .flow-card {
    max-width: 520px;
    z-index: 10;
    position: relative;
  }

  &__progress {
    padding-left: 32px;
    padding-right: 32px;
    padding-top: 32px;

    img {
      height: 64px;
    }

    @include media-breakpoint(min, md) {
      display: none;
    }
  }

  .landing {
    background-color: $primary-default;
    color: white;
  }

  .text-muted {
    color: $primary-secondary;

    > .text {
      color: $action-active;
    }
  }

  .error-message {
    color: $red;
  }

  .account-agreement a {
    color: $action-active;
  }

  .desktop-heading {
    font-size: 30px;
    line-height: 40px;
    font-weight: 800;
  }

  .desktop-heading-signup {
    font-size: 30px;
    line-height: 40px;
    font-weight: 800;
  }

  .progress-container {
    flex: 1;
  }

  .flow-image {
    margin: 0 auto 30px;
  }

  .address-dropdown-item {
    width: auto;
    height: 60px;
    border: 1px solid;
    border-color: #9393aa;
    border-top: 0;
    font-family: $avenir;
    display: flex;
    align-items: center;
    padding: 10px;

    &:hover {
      color: rgb(13, 41, 115);
      background: rgb(234 240 255);
    }
  }

  .upload-photo-container {
    margin: 65px 0;
  }

  .photo-id-info {
    flex: 1;
  }

  .checkbox-flex {
    flex-wrap: initial;

    input[type='checkbox'] {
      width: 24px;
      height: 24px;
      border: 1px solid $primary-secondary;
      border-radius: 4px;
      margin-right: 16px;
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      position: relative;
      background-color: #ffffff;
      border-color: $primary-default;

      &:checked {
        background-color: $primary-default;
        border-color: $primary-secondary;
      }

      &:checked:after {
        content: url('../../assets/palOnboarding/check.svg');
        position: absolute;
        top: 1px;
        left: 0;
        transform: scale(0.7);
      }
    }
  }

  .hideMobile {
    @include media-breakpoint(max, md) {
      display: none;
    }
  }

  .benefitsApplyButton {
    position: relative;
    left: 60%;
    top: -230px;
    @include media-breakpoint(max, xl) {
      top: -150px;
    }
  }

  .experiencesApplyButton {
    position: relative;
    left: 60%;
    top: -330px;
    @include media-breakpoint(max, xl) {
      top: -170px;
    }
  }
}

.edit-modal-backdrop {
  height: 100%;
  width: 100%;
  background-color: $white;
  position: fixed;
  display: flex;
}

.edit-modal {
  flex: 1;
  overflow-y: auto;
  background-color: $white;

  &__header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 20px 32px;
    border-bottom: 1px solid #ccc;
    position: relative;

    .close {
      cursor: pointer;
    }

    button > .text {
      color: $action-active;
    }

    .title {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translateX(-50%) translateY(-50%);
    }
  }

  &__content {
    padding: 32px;
  }
}
input.filled {
  background-color: $primary-default !important;
  color: #ffffff !important;
}

.hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.phone-verification-inputs {
  width: 100% !important;

  > div {
    display: flex;
    flex-direction: row;
  }

  input {
    flex: 1 0 auto;
    height: 75px !important;
    font-family: $avenir !important;
    font-size: 30px !important;
    background-color: #ffffff;
    border: 1px solid #9393aa !important;
    border-radius: 5px !important;
    margin-right: 16px;

    &:last-of-type {
      margin-right: 0;
    }

    &:focus {
      color: $primary-default;
      background-color: #f2f5ff;
    }
  }

  &--invalid input {
    background-color: #fff9fa;
    border: 1px solid #eb5669 !important;
  }
}

.principle-card {
  background-color: $white;
  border: 2px solid transparent;
  border-radius: 20px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.15);
  padding: 32px 32px 20px 32px;

  &__content {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
  }

  img {
    width: 100px;
    height: auto;
  }

  .read-more {
    width: 100%;
    display: block;
    text-align: center;
    border-top: 1px solid $background-default;
    padding-top: 16px;
    margin-top: 16px;
  }
}

.onboarding-radio {
  background-color: $white;
  border: 2px solid transparent;
  border-radius: 20px;
  filter: drop-shadow(0px 0px 5px rgba(0, 0, 0, 0.15));
  margin-bottom: 16px;

  &--incorrect {
    border-color: $red;
  }

  &--correct {
    border-color: $action-active;
  }

  .text {
    flex: 1;
  }

  label {
    display: flex;
    justify-content: space-between;
    padding: 24px;
  }

  input {
    display: none;
  }

  &__circle {
    width: 24px;
    height: 24px;
    border: 1px solid $primary-secondary;
    border-radius: 24px;
    position: relative;
  }

  input:checked ~ &__circle {
    border-color: $primary-default;

    &:after {
      content: '';
      position: absolute;
      top: 2px;
      left: 2px;
      width: 18px;
      height: 18px;
      background: $primary-default;
      border-radius: 18px;
    }
  }
}

.quiz-passed {
  height: 100%;
  background-color: rgba($primary-default, 0.9);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .text {
    color: $white;
    margin-bottom: 50px;
  }
}

.quiz-failed {
  height: 100%;
  display: flex;
  align-items: center;
  background-color: $primary-default;

  .flow-button {
    color: $background-default;
    background-color: $primary-default;
    border-color: $primary-default;
    padding: 24px 60px;
  }

  &__card {
    display: flex;
    flex-direction: column;
    background-color: $white;
    border-radius: 20px;
    padding: 30px;
    text-align: center;

    &__review {
      text-align: left;
      width: auto;
      position: relative;

      img {
        margin-left: auto;
        right: 28px;
      }
    }
  }
}

.agreements-modal {
  height: 100vh;
  background-color: $primary-default;
  padding-top: 64px;
  display: flex;

  &__card {
    flex: 1;
    min-height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    background-color: $white;
    border-radius: 20px 20px 0 0;
    padding: 30px;
  }

  .document-box {
    flex: 1;
    font-family: $avenir;
    background-color: $background-default;
    border-radius: 20px;
    padding: 24px;
    overflow-y: auto;
    margin: 30px 0;
    word-wrap: break-word;

    h1,
    h2,
    h3,
    h4,
    h5 {
      font-size: 16px;
    }
  }
}

.backgroundCheck {
  &__icon {
    width: 24px;
    height: 24px;
    margin-right: 10px;
  }

  &__modalButton {
    width: 100%;
    display: flex;
    justify-content: center;
    text-align: center;

    img {
      margin-left: 5px;
    }
  }

  .ssn-input {
    position: relative;

    &__icon {
      position: absolute;
      top: 50%;
      transform: translateY(-65%);
      right: 28px;
    }

    &--label-icon {
      display: none;

      &.reveal {
        display: inline;
        margin-right: 2px;
        width: 1em;
      }
    }

    input {
      color: rgba(0, 0, 0, 0);
    }

    .redacted-label {
      position: absolute;
      left: 0.75em;
      font-size: 1.25em;
      margin-top: 1.25em;
    }
  }
}

.backgroundCheck-modal {
  min-height: 100%;
  background-color: #18181a;
  padding-top: 64px;

  &__card {
    height: 100%;
    display: flex;
    flex-direction: column;
    background-color: $white;
    border-radius: 20px 20px 0 0;
    padding: 30px;
  }

  &__icon {
    width: 24px;
    height: 24px;
    margin-right: 10px;
  }

  ul {
    padding-left: 54px;
  }

  .flow-button--default {
    color: $white;
    background-color: $primary-default;
    border-color: $primary-default;
  }
}

.onboarding-finished {
  font-family: $avenir;
  &__header {
    font-size: 34px;
    font-weight: 400;
  }

  &__image {
    max-width: 350px;
    height: auto;
    margin: 0 auto;
  }

  &__button {
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 24px 40px;
  }
}

.vaccine {
  font-family: $avenir;
  &__image {
    max-width: 300px;
    height: auto;
    margin: 0 auto;
    left: 75px;
  }

  &__button {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    padding: 18px;
    border: 1px solid rgba(38, 38, 69, 0.5);
    box-sizing: border-box;
    border-radius: 100px;
    font-size: 20px;
  }
}

.gateway {
  &__image {
    margin: -32px;
    img {
      max-width: 100%;
    }
  }

  &__button {
    font-size: 20px;
    width: 100%;
    padding: 18px;
  }
}

.form-page {
  .form-section {
    padding: 100px 0;

    .to-sales {
      margin-bottom: 50px;

      h3 {
        font-size: 21px;

        a {
          color: $blue;
          border-bottom: 1px solid $blue;
        }
      }

      hr {
        border-width: 2px;
      }
    }

    h2 {
      font-size: 45px;
    }

    .form-group {
      margin-bottom: 50px;

      .form-label {
        display: block;
        font-size: 24px;
        font-weight: 600;
        margin-bottom: 15px;
      }

      .form-control {
        height: 56px;
        padding-left: 20px;
      }

      textarea.form-control {
        height: auto;
      }
    }

    .form-group.description {
      .form-label {
        margin-bottom: 10px;
      }

      p {
        font-size: 14px;
        line-height: 1.75;
        margin-bottom: 15px;
      }
    }
  }
}

.telehealth {
  .page-header {
    padding-bottom: 0;
  }

  h3 {
    font-size: 24px;
  }

  .reverse {
    @include media-breakpoint(max, md) {
      flex-direction: column-reverse;
    }
  }

  .overview {
    color: $black;

    h2 {
      font-size: 40px;
      text-align: center;
    }

    h3 {
      color: $indigo;
    }

    p.lead {
      font-size: 1.25rem;
      line-height: 1.5;
    }

    p:not(.lead) {
      font-size: 17px;
      line-height: 1.75;
    }

    .flex {
      flex-wrap: initial;

      .content {
        flex: 1;
      }
    }

    .number {
      width: 42px;
      height: 42px;
      color: $red;
      font-size: 28px;
      border: 3px solid $red;
      border-radius: 50%;
    }

    a {
      color: $blue;
      text-decoration: underline;
    }
  }

  .panel {
    color: $white;
    background: $indigo;
    padding: 35px;
    margin-bottom: 35px;

    hr {
      max-width: 260px;
      border-color: $white;
    }
  }

  .form-section .form-group {
    margin-bottom: 30px;

    .form-label {
      font-size: 14px;
    }

    .button {
      width: 100%;
    }
  }
}

.app-buttons {
  display: flex;
  flex-direction: column;
  align-items: center;

  .application-store-logo {
    height: 60px;
  }

  .appstore {
    margin-bottom: 20px;
  }

  @include media-breakpoint(min, lg) {
    flex-direction: row;
    justify-items: center;

    .appstore {
      margin-right: 20px;
      margin-bottom: 0px;
    }
  }
}
@use 'sass:math';

.container {
  width: 100%;
  max-width: 1180px;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin-left: math.div( -$grid-gutter, 2);
  margin-right: math.div( -$grid-gutter, 2);
}

@include make-grid-columns();

.banner {
  color: $white;
  font-size: 14px;
  background: $indigo;
  padding: 30px 0;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 10;

  &__content {
    flex: 1;
    font-size: 15px;

    a {
      color: $white;
      text-decoration: underline;
    }
  }

  .button {
    padding: 12px 24px;
  }
}

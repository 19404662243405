@import 'stylesheets/_variables.scss';

.eula {
  background-color: $white;
  color: $primary-default;
  font-family: $avenir $native-stack;
  height: 100vh;
  left: 0;
  overflow: scroll;
  position: fixed;
  top: 0;

  &-loading {
    left: 45vw;
    position: fixed;
    top: 45vh;
    width: 100vw;
  }

  &-error {
    align-items: center;
    display: flex;
    height: 100vh;
    width: 100vw;
    padding: 2em;
  }

  &-agreement {
    display: flex;
    flex-direction: column;
    gap: 1em;
    padding: 1em 2em;
  }
  &-title {
    display: flex;
    font-size: 1.25em;
    font-weight: 500;
    justify-content: center;
    margin: 1em auto;
  }

  &-subtitle {
    font-size: 1.5em;
    font-weight: 700;
  }

  &-subtext {
    font-weight: 700;

    &-instructions {
      font-size: 1em;
      margin-bottom: 0.5em;
    }
    &-date {
      font-size: 0.75em;
    }
  }

  &-contents {
    padding-bottom: 10em;
    &-text {
      font-size: 0.75em;
    }
    &-button {
      background-color: rgba(255, 255, 255, 0.9);
      bottom: 0;
      box-shadow: 0 0 24px 24px $white;
      left: 0;
      margin: auto;
      padding: 2em;
      position: fixed;
      width: 100%;
    }
  }
}

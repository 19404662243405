.flow h2 {
  margin-bottom: 20px;
  margin-top: 60px;
}

.flow-button {
  display: inline-block;
  border: none;
  border-radius: 100px;
  font-family: $avenir;
  font-weight: 400;

  &--block {
    display: block;
    width: 100%;
    text-align: center;
  }

  &--base {
    font-size: 14px;
    line-height: 20px;
    padding: 10px 38px;
  }

  &--lg {
    font-size: 20px;
    line-height: 28px;
    padding: 24px 80px;
  }

  &--default {
    color: $white;
    background-color: $primary-default;
  }

  &--primary {
    color: $primary-color;
    background-color: $action-active;
  }

  &--secondary {
    color: $primary-default;
    background-color: $white;
    border: 1px solid rgba(38, 38, 69, 0.5);
  }

  &--disabled {
    color: $white;
    background-color: $background-disabled;
    cursor: default;
    box-shadow: none;
  }

  &:hover {
    box-shadow: none;
  }

  @media (hover: none) {
    &:hover:enabled {
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    }
  }

  @media (hover: none) {
    &:active:enabled {
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    }
  }
}

.flow-form-input {
  background-color: transparent;
  border: 1px solid $primary-secondary;
  border-radius: 5px;
  color: $primary-default;
  font-family: $avenir;
  font-size: 20px;
  height: auto;
  line-height: 32px;
  width: 100%;
  padding: 16px;

  &--withIcon {
    padding-right: 40px;
  }

  &--muted {
    color: $primary-secondary;
  }

  &--muted + .form-ddl-floating-label {
    display: none;
  }
  + .ddl-floating-label {
    display: none;
  }
  &--error {
    background-color: #fff9fa;
    border: 1px solid $red;
  }

  &::placeholder {
    color: $primary-secondary;
    opacity: 1;
  }

  :-ms-input-placeholder {
    color: $primary-secondary;
  }

  ::-ms-input-placeholder {
    color: $primary-secondary;
  }
}

.flow-form--select {
  align-items: center;
  border: 1px solid $primary-secondary;
  border-radius: 5px;
  color: $primary-default;
  display: grid;
  grid-template-areas: 'select';
  position: relative;
  width: 100%;

  select {
    background-color: transparent;
    border: none;
    margin: 16px;
    padding-left: 0px;

    &:focus {
      background-color: transparent;
    }
    &::placeholder {
      color: $primary-secondary;
      opacity: 1;
    }
    &::after {
      grid-area: select;
    }
    &::after.disabled {
      position: absolute;
      top: 0px;
    }
    + .ddl-floating-label {
      display: none;
    }
    &.selected + .ddl-floating-label {
      top: 0px;
    }

    &.disabled {
      border-color: $background-disabled;
      color: $background-disabled;
      pointer-events: none;
    }
  }
}

@mixin floating-label {
  position: absolute;
  color: #9393aa;
  font-size: 20px;
  white-space: nowrap;
  pointer-events: none;
  left: 16px;
  transition: 0.2s ease all;
}
.floating-label,
.floating-label-not-required {
  margin-top: 20px;
  @include floating-label;
}
.mt-200 {
  margin-top: 200px;
}
.mt-30 ~ .floating-label {
  margin-top: 50px;
  @include floating-label;
}
input.flow-form-input:focus {
  background-color: #f2f5ff;
  outline: none !important;
}

select:focus {
  outline: $primary-secondary;
  background-color: #f2f5ff;
}

@mixin text-label {
  color: $primary-default;
  font-size: 12px;
  left: 16px;
}
.form-ddl-floating-label {
  position: absolute;
  transition: 0.2s ease all;
  margin-top: 5px;
  @include text-label;
}

select.selected + .ddl-floating-label {
  position: absolute;
  transition: 0.2s ease all;
  margin-top: 5px;
  font-size: 12px;
  left: 16px;
  display: inline;
}
input.flow-form-input {
  &:focus + .floating-label-not-required,
  &:not(:placeholder-shown) + .floating-label-not-required {
    margin-top: 5px;
    @include text-label;
  }
  &:focus + .floating-label,
  &:not(:focus):valid + .floating-label,
  &.not-empty + .floating-label {
    margin-top: 5px;
    @include text-label;
  }
  &.mt-30 {
    &:focus + .floating-label,
    &:not(:focus):valid + .floating-label {
      margin-top: 35px;
      @include text-label;
    }
  }
  &:disabled {
    background-color: rgb(237, 231, 231, 0.5);
  }
}

.flow-form-select {
  display: inline-block;
  position: relative;

  &__caret {
    position: absolute;
    top: 50%;
    right: 20px;
    left: auto;
    bottom: auto;
    transform: translateY(-25%);
  }
}

.upload-image {
  align-items: center;
  background-color: $background-default;
  border: 1px solid $primary-secondary;
  border-radius: 100px;
  display: inline-flex;
  justify-content: center;
  height: 200px;
  width: 200px;
}

.tabs {
  background-color: $background-default;
  border-radius: 100px;
  display: inline-flex;
  height: 47px;

  &__button {
    color: $background-secondary;
    font-family: $avenir;
    font-weight: 700;
    min-width: 175px;
    border-radius: 100px;
  }

  &__button--active {
    color: $white;
    background-color: $action-active;
  }
}

.progress-bar {
  height: 6px;
  background-color: rgba(147, 147, 170, 0.5);
  border-radius: 3px;
  position: relative;
  overflow: hidden;

  &__fill {
    background-color: $primary-default;
    border-radius: 3px;
    transition: all 500ms ease;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
  }
}

.card {
  background-color: $white;
  border: 2px solid transparent;
  border-radius: 20px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.15);
  display: flex;
  align-items: center;
  padding: 20px;

  &--active {
    border: 2px solid $action-active;
  }

  &__content {
    flex: 1;
  }

  &__image {
    max-width: 120px;
    margin-left: 10px;
  }
}

.id-confirm-card {
  border: 2px solid transparent;
  border-radius: 20px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.15);
  padding-top: 30px;
  padding-left: 30px;
  padding-right: 30px;

  .item > .text {
    flex: 1;
  }
}

.text {
  color: $primary-default;
  font-family: $avenir;

  > .text {
    display: inline;
  }

  &--display-heavy {
    font-size: 30px;
    font-weight: 700;
    line-height: 40px;
  }

  &--display-light {
    font-size: 30px;
    line-height: 40px;
  }

  &--headline-heavy {
    font-size: 24px;
    font-weight: 700;
    line-height: 32px;
  }

  &--headline-light {
    font-size: 24px;
    line-height: 32px;
  }

  &--title-heavy {
    font-size: 20px;
    font-weight: 700;
    line-height: 28px;
  }

  &--title-light {
    font-size: 20px;
    line-height: 28px;
  }

  &--subheader-heavy {
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
  }

  &--subheader-light {
    font-size: 16px;
    line-height: 24px;
  }

  &--body-heavy {
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
  }

  &--body-light {
    font-size: 14px;
    line-height: 20px;
  }

  &--caption-heavy {
    font-size: 12px;
    font-weight: 700;
    line-height: 16px;
  }

  &--caption-light {
    font-size: 12px;
    line-height: 16px;
  }

  &--small-heavy {
    font-size: 10px;
    font-weight: 700;
    line-height: 14px;
  }

  &--small-light {
    font-size: 10px;
    line-height: 14px;
  }

  &--validation-default {
    margin-right: 10px;
    color: $primary-default;
    background: #f2f5ff;
    border-radius: 6px;
    padding: 5px 8px;
    width: fit-content;
    font-size: 12px;
    line-height: 16px;
  }

  &--validation-pass {
    margin-right: 10px;
    color: $primary-default;
    background: #e6f3ec;
    border-radius: 6px;
    padding: 5px 8px;
    width: fit-content;
    font-size: 12px;
    line-height: 16px;
  }

  .bold {
    font-weight: 700;
  }
}

.toggle-visibility {
  position: relative;
  top: -43px;
  float: right;
  margin-right: 15px;
  cursor: pointer;
}

.check-mark-default {
  margin-right: 8px;
  color: #e4e5e6;
}

.email-check-mark-pass {
  margin-top: 20px;
  right: 10px;
  position: absolute;
  color: #0b8844;
}

.email-check-mark-error {
  margin-top: 20px;
  right: 10px;
  position: absolute;
  color: #eb5669;
}

.check-mark-pass {
  margin-right: 8px;
  color: #0b8844;
}

.Toastify__close-button {
  margin-top: 66px !important;
  color: #fff !important;
  opacity: 1 !important;
}

.Toastify__close-button > svg {
  width: 22px !important;
  height: 22px !important;
}

.Toastify__toast {
  opacity: 0.95 !important;
  padding-left: 20px !important;
}

.Toastify__toast--error {
  background: #cc142c !important;
  &.customErrorTitle {
    background: #ffeaed !important;
  }
}
.customErrorTitle {
  .Toastify__close-button {
    color: red !important;
  }
  .toastBody {
    padding-left: 31px;
  }
  .toast-icon {
    margin-right: 7px;
  }
}

.customSuccessTitle {
  .Toastify__close-button {
    color: #09743a !important;
  }
  .toastBody {
    padding-left: 31px;
  }
  .toast-icon {
    margin-right: 7px;
  }
}
.Toastify__toast--success {
  background: green !important;
  .Toastify__close-button {
    margin-top: 63px !important;
  }
  &.customSuccessTitle {
    background: #e6f3ec !important;
  }
}
.inputError {
  border: 1px solid #eb5669;
  background: #fff9fa;
}
.error {
  color: red;
}
.toastBody {
  color: #fff;
  margin-bottom: 18px;
  font-size: 14px;
}

.Toastify__toast-icon {
  display: none !important;
}
.customErrorBody {
  .toastBody,
  .toastTitle {
    color: red;
  }
}
.customSuccessBody {
  .toastBody,
  .toastTitle {
    color: #09743a;
  }
}
.toastTitle {
  color: #fff;
  font-weight: 700;
  margin-top: 60px;
  font-size: 14px;
  display: flex;
}

.validation-container {
  margin-top: 1rem;
}

.disabledBackground {
  position: absolute;
  background: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 11;
  border: solid #fff 5px;
}

.hide {
  display: none;
}

.alignCenter {
  display: flex;
  justify-content: center;
  background-color: white;
  padding: 5px;
  z-index: 12;
  position: absolute;
  top: 382px;
  left: calc(50% - 29px);
  @include media-breakpoint(min, md) {
    left: 256px;
    top: 446px;
  }
}

.email-verification {
  &__text {
    font-size: 34px;
  }
  &__button {
    margin-top: 65px;
  }
  &__image {
    margin-top: 50px;
    margin-left: auto;
    margin-right: auto;
    display: block;
    cursor: pointer;
  }
}

.fadeOut {
  opacity: 0.2;
  transition: 0.8s;
}

.processing {
  width: 48px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.resend-button {
  color: $primary-default;
  background-color: $white;
  font-size: 13px;
  font-weight: 500;
  font-family: $avenir;
  padding: 16px;
  border: 1px solid $primary-default;
  border-radius: 100px;
}

.flow-radio {
  background-color: $white;
  display: flex;
  align-items: flex-start;
  gap: 16px;

  &__content {
    display: flex;
  }
}

.flow-radio-button {
  -webkit-appearance: none;
  appearance: none;
  display: grid;
  place-content: center;
  flex-shrink: 0;
  font: inherit;
  color: currentColor;
  background-color: #dcdcdc;
  accent-color: $primary-default;
  margin: 0;
  width: 1.5em;
  height: 1.5em;
  border: 0.15em solid #cfcfcf;
  border-radius: 50%;
  &:checked {
    background-color: #fff;
    border: 0.5em solid $primary-default;
  }
}

.info-card {
  align-items: flex-start;
  border-radius: 5px;
  background-color: $red-transparent;
  color: $black;
  display: flex;
  padding: 16px;
  gap: 10px;

  &.car {
    background: $blue-50;
    > .text {
      color: rgb(var(--blue-700));
    }
  }
}

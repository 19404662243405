.footer {
  color: $white;
  line-height: 24px;
  background: $indigo;
  padding: 60px 0 30px;

  &__main {
    @include media-breakpoint(max, md) {
      margin-bottom: 50px;
    }

    .brand {
      max-width: 108px;
    }

    a {
      color: $white;
    }
  }

  img {
    &.allina-footer {
      width: auto;
      height: 20px;
      margin-right: 30px;
      @include media-breakpoint(min, md) {
        height: 30px;
        margin-right: 47px;
      }
    }

    &.cigna-logo-footer {
      width: auto;
      height: 58px;
      @include media-breakpoint(min, md) {
        height: 90px;
        margin: 22px;
      }
    }
  }

  &__social img {
    display: inline-block;
    max-width: 46px;
  }

  h4 {
    font-size: 24px;
    line-height: 34px;
  }

  ul {
    list-style: none;
    padding: 0;
    @include media-breakpoint(max, md) {
      margin-bottom: 50px;
    }

    li:first-of-type {
      font-size: 18px;
      font-weight: 700;
      font-family: $avenir;
    }

    a {
      color: $white;
    }
  }

  hr {
    border-width: 1px;
  }

  .legal {
    font-size: 14px;

    a {
      color: $white;
    }
  }
}

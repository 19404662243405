// Layout utilities
.flex {
  display: flex;
  flex-wrap: wrap;
}

.flex-1 {
  flex: 1 1 0%;
}

.flex-grow {
  flex-grow: 1;
}

.align-start {
  align-items: flex-start;
}

.items-center,
.align-center {
  align-items: center;
}

.align-stretch {
  align-items: stretch;
}

.justify-center {
  justify-content: center;
}

.space-between {
  justify-content: space-between;
}

.space-evenly {
  justify-content: space-evenly;
}

.direction-column {
  flex-direction: column;
}

.direction-row {
  flex-direction: row;
}

.direction-row-reverse {
  flex-direction: row-reverse;
}

.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.height-100pct {
  height: 100%;
}

.text-uppercase {
  text-transform: uppercase;
}

.text-error {
  color: $red;
}

// Responsive utilities
.hidden-mobile {
  @include media-breakpoint(max, md) {
    display: none !important;
  }
}

.hidden-desktop {
  @include media-breakpoint(min, md) {
    display: none;
  }
}

// Margin spacing
@each $space in $space-amounts {
  @each $side in $space-sides {
    .m#{str-slice($side, 0, 1)}-#{$space} {
      @if $space == auto {
        margin-#{$side}: #{$space};
      } @else {
        margin-#{$side}: #{$space}px;
      }
    }
  }
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.form-group {
  margin-bottom: 15px;
}

.form-control-wrapper {
  position: relative;

  img {
    position: absolute;
    top: 45%;
    right: 20px;
  }
}

.form-control {
  display: block;
  width: 100%;
  color: #5c74b8;
  background: #f7f9fc;
  border: 1px solid #e4e9f2;
  border-radius: 4px;
  padding: 10px;

  &:focus {
    outline: none;
  }

  &::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #8f9bb3;
    opacity: 1; /* Firefox */
  }

  &:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: #8f9bb3;
  }

  &::-ms-input-placeholder {
    /* Microsoft Edge */
    color: #8f9bb3;
  }

  &--valid,
  &--valid:focus {
    border-color: $green;
  }

  &--invalid,
  &--invalid:focus {
    border-color: $red;
  }
}

.form-feedback {
  color: #777;
  margin-top: 5px;

  &--valid {
    color: $green;
  }

  &--invalid {
    color: $red;
  }
}

.form-control--invalid {
  border-color: $red;
}

button {
  background: none;
  border: 0;
  cursor: pointer;
}

.button {
  display: inline-block;
  font-weight: 600;
  line-height: 1.5;
  text-decoration: none;
  text-transform: uppercase;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 4px 6px 0 rgba(0, 0, 0, 0.05);
  border-radius: 30px;
  padding: 15px 30px;
}

.button--primary {
  color: $white;
  background: $green;
}

.button--nofill {
  color: $primary-default;
  font-family: $avenir;
}

.button--loading {
  opacity: 0.65;
  pointer-events: none;
  cursor: default;
}

.loading-icon {
  width: 18px;
  margin-left: 8px;
  animation: spin 2s linear infinite;
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

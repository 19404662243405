.pal-experience-row {
  margin-bottom: 60px;
  @include media-breakpoint(min, md) {
    margin-bottom: 100px;
  }

  img {
    width: 100%;
    border-radius: 8px;
    margin-bottom: 40px;
    @include media-breakpoint(min, md) {
      transform: translateX(-10%);
      margin-bottom: 0;
    }
  }

  h4 {
    font-size: 24px;
    line-height: 34px;
    letter-spacing: -0.51px;
    @include media-breakpoint(min, md) {
      font-size: 42px;
      line-height: 60px;
    }
  }

  p {
    font-size: 20px;
    line-height: 28px;
    letter-spacing: -0.014em;

    span {
      color: $red;
    }
  }

  &:nth-child(odd):not(.no-reverse) {
    flex-direction: row-reverse;
  }

  .paragraphWrapper {
    margin-right: 64px;
  }
}

.visit-rating {
  &__content {
    padding: 70px 0;

    h2 {
      font-size: 38px;
      font-weight: 700;
      margin-bottom: 120px;
      position: relative;

      &:after {
        content: "";
        width: 130px;
        height: 8px;
        background: $slate;
        position: absolute;
        bottom: -25px;
        left: 0;
      }
    }

    h3 {
      width: 100%;
      font-size: 26px;
      text-align: center;
      padding: 0 30px;
      margin-bottom: 30px;
    }

    .panel {
      background: #f7f9fc;
      text-align: center;
      padding: 40px 10px;
      margin-bottom: 100px;

      p {
        font-size: 26px;
        font-weight: 600;
        margin-top: 40px;
      }
    }

    .form-control {
      font-size: 18px;
      padding: 25px;
      margin-bottom: 80px;
    }

    .button {
      width: 100%;
      max-width: 410px;
    }
  }
}

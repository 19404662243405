// Brand colors
$indigo: #272644;
$blue: #5c74b8;
$slate: #304d6d;
$green: #00a56c;
$orange: #eb6f0e;
$red: #eb5669;
$red-transparent: rgba(235, 86, 105, 0.5);
$smoke: #f9f9f6;
$white: #ffffff;
$carbon: #4a4a4a;
$black: #2b292d;
$primary-color: #e6f3ec;
$label-color: #6e6e6e;
$border-color: #d8dde0;

// Application flow
$primary-default: #262645;
$primary-secondary: #9393aa;
$action-active: #0b8844;
$background-default: #f1f2f6;
$background-secondary: #66686b;
$background-disabled: #c6c6cc;
$neutral-gray: #f7f8ff;

$blue-50: #e5f5ff;

// Material Colors
:root {
  --blue-50: 229, 245, 255;
  --blue-500: 0, 131, 216;
  --blue-700: 0, 85, 139;
  --green-3: 0, 173, 32;
  --yellow-2: 255, 227, 15;
  --grey-1: 225, 225, 225;
}

// Typography
$native-stack: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif;
$wavehaus: 'Wavehaus', $native-stack;
$avenir: 'Avenir', $native-stack;
$inter: 'Inter', $native-stack;
$open-sans: 'Open Sans', $native-stack;

// Media and grid breakpoints
$breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
);

// Grid columns
$grid-columns: 12;
$grid-gutter: 30px;

// Spacing in px
$space-amounts: (0, 5, 10, 15, 20, 25, 30, 45, 60, 72, auto);
$space-sides: (top, bottom, left, right);

// Transparency
@function transparency($hex, $alpha) {
  @return $hex +#{$alpha};
}

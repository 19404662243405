@font-face {
  font-family: "Wavehaus";
  font-weight: 400;
  font-style: normal;
  font-display: swap; /* Swap fonts after download */
  unicode-range: U+000-5FF; /* Download only latin glyphs */
  src: local("Wavehaus"), url("../fonts/wavehaus-bold.otf") format("opentype");
}

@font-face {
  font-family: "Avenir";
  font-weight: 400;
  font-style: normal;
  font-display: swap; /* Swap fonts after download */
  unicode-range: U+000-5FF; /* Download only latin glyphs */
  src: local("Avenir Book"), local("Avenir-400"),
    url("../fonts/Avenir-Book.woff2") format("woff2"),
    url("../fonts/Avenir-Book.woff") format("woff");
}

@font-face {
  font-family: "Avenir";
  font-weight: 500;
  font-style: normal;
  font-display: swap; /* Swap fonts after download */
  unicode-range: U+000-5FF; /* Download only latin glyphs */
  src: local("Avenir Medium"), local("Avenir-500"),
    url("../fonts/Avenir-Medium.woff2") format("woff2"),
    url("../fonts/Avenir-Medium.woff") format("woff");
}

@font-face {
  font-family: "Avenir";
  font-weight: 700;
  font-style: normal;
  font-display: swap; /* Swap fonts after download */
  unicode-range: U+000-5FF; /* Download only latin glyphs */
  src: local("Avenir Heavy"), local("Avenir-700"),
    url("../fonts/Avenir-Heavy.woff2") format("woff2"),
    url("../fonts/Avenir-Heavy.woff") format("woff");
}

@font-face {
  font-family: "Avenir";
  font-weight: 900;
  font-style: normal;
  font-display: swap; /* Swap fonts after download */
  unicode-range: U+000-5FF; /* Download only latin glyphs */
  src: local("Avenir Black"), local("Avenir-900"),
    url("../fonts/Avenir-Black.woff2") format("woff2"),
    url("../fonts/Avenir-Black.woff") format("woff");
}

@font-face {
  font-family: "Inter";
  font-weight: 400;
  font-style: normal;
  font-display: swap; /* Swap fonts after download */
  unicode-range: U+000-5FF; /* Download only latin glyphs */
  src: local("Inter"), url("../fonts/inter-regular.woff2") format("woff2"),
    url("../fonts/inter-regular.woff") format("woff");
}

@font-face {
  font-family: "Inter";
  font-weight: 500;
  font-style: normal;
  font-display: swap; /* Swap fonts after download */
  unicode-range: U+000-5FF; /* Download only latin glyphs */
  src: local("Inter Medium"), url("../fonts/inter-medium.woff2") format("woff2"),
    url("../fonts/inter-medium.woff") format("woff");
}

@font-face {
  font-family: "Inter";
  font-weight: 600;
  font-style: normal;
  font-display: swap; /* Swap fonts after download */
  unicode-range: U+000-5FF; /* Download only latin glyphs */
  src: local("Inter Semibold"),
    url("../fonts/inter-semibold.woff2") format("woff2"),
    url("../fonts/inter-semibold.woff") format("woff");
}

@font-face {
  font-family: "Inter";
  font-weight: 700;
  font-style: normal;
  font-display: swap; /* Swap fonts after download */
  unicode-range: U+000-5FF; /* Download only latin glyphs */
  src: local("Inter Bold"), url("../fonts/inter-bold.woff2") format("woff2"),
    url("../fonts/inter-bold.woff") format("woff");
}

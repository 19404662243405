@use 'sass:math';
// Media query mixin

@function get-breakpoint($breakpoint) {
  @return map-get($breakpoints, $breakpoint);
}

@mixin media-breakpoint($direction, $size) {
  @media (#{$direction}-width: get-breakpoint($size)) {
    @content;
  }
}

// Grid framework
@function breakpoint-min($name, $breakpoints: $breakpoints) {
  $min: map-get($breakpoints, $name);
  @return if($min != 0, $min, null);
}

@function breakpoint-infix($name, $breakpoints: $breakpoints) {
  @return if(breakpoint-min($name, $breakpoints) == null, "", "-#{$name}");
}

@mixin make-col($size, $columns: $grid-columns) {
  flex: 0 0 percentage(math.div($size, $columns));
  max-width: percentage(math.div($size, $columns));
}

@mixin make-col-offset($size, $columns: $grid-columns) {
  $num: math.div($size, $columns);
  margin-left: if($num == 0, 0, percentage($num));
}

@mixin make-grid-columns(
  $columns: $grid-columns,
  $gutter: $grid-gutter,
  $breakpoints: $breakpoints
) {
  %grid-column {
    position: relative;
    width: 100%;
    padding-right: math.div($gutter, 2);
    padding-left: math.div($gutter, 2);
  }

  @each $breakpoint in map-keys($breakpoints) {
    $infix: breakpoint-infix($breakpoint, $breakpoints);

    @for $i from 1 through $columns {
      .col#{$infix}-#{$i} {
        @extend %grid-column;
      }
    }

    @include media-breakpoint(min, $breakpoint) {
      .col#{$infix} {
        flex-basis: 0;
        flex-grow: 1;
        max-width: 100%;
      }

      @for $i from 1 through $columns {
        .col#{$infix}-#{$i} {
          @include make-col($i, $columns);
        }
      }

      @for $i from 0 through ($columns - 1) {
        @if not($infix == "" and $i == 0) {
          .offset#{$infix}-#{$i} {
            @include make-col-offset($i, $columns);
          }
        }
      }
    }
  }
}

.password-strength-meter {
  display: flex;
  width: 100%;

  &--bar {
    background-color: rgb(var(--grey-1));
    border-radius: 6.25rem;
    height: 0.5rem;
    flex-grow: 0;
    flex-basis: 25%;
    margin: 4px;
    max-width: 25%;

    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      margin-right: 0;
    }

    &.warning {
      background-color: rgb(var(--yellow-2));
    }
    &.success {
      background-color: rgb(var(--green-3));
    }
  }

  &--suggestion {
    display: flex;
    margin-top: 1rem;

    .success {
      color: rgb(var(--green-3));
    }
    .warning {
      color: rgb(var(--yellow-2));
    }

    svg {
      flex-shrink: 0;
      height: 24px;
      margin-right: 8px;
      width: 24px;
    }
  }
}

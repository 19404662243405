// .hidden-desktop {
//   @include media-breakpoint(max, md) {
//     display: none !important;
//   }
// }

.navbar {
  height: 100px;
  color: $white;
  background: $indigo;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
  transition: background 200ms ease-in-out;
  @include media-breakpoint(min, md) {
    height: 140px;
  }

  &--menu-open {
    background: #4a4a4a;
  }

  > .container {
    @include media-breakpoint(max, md) {
      flex-direction: row;
    }
  }

  &__brand {
    @include media-breakpoint(min, md) {
      margin-right: 30px;
    }

    a {
      display: flex !important;
      flex-direction: row;
      align-items: center;
    }

    &.center-brand {
      flex: 1;
    }

    img {
      width: 64px;
      margin-left: 15px;
      @include media-breakpoint(min, md) {
        width: 90px;
      }

      &.aetna {
        width: auto;
        height: 40px;
        margin-right: 30px;
        @include media-breakpoint(min, md) {
          height: 57px;
          margin-right: 47px;
        }
      }

      &.lotsa {
        width: auto;
        height: 64px;
        @include media-breakpoint(min, md) {
          height: 90px;
        }
      }

      &.bcbsri {
        width: auto;
        height: 58px;
        padding-right: 22px;
        border-right: 2px solid white;
        @include media-breakpoint(min, md) {
          height: 90px;
          margin: 22px;
        }
      }

      &.highmark {
        width: auto;
        height: 30px;
        margin-top: 17px;
        padding-right: 22px;
        @include media-breakpoint(min, md) {
          height: 60px;
        }
      }

      &.cigna-logo {
        width: auto;
        height: 58px;
        padding-right: 22px;
        @include media-breakpoint(min, md) {
          height: 90px;
          margin: 22px;
        }
      }

      &.kaiser {
        width: auto;
        height: 26px;
        @include media-breakpoint(min, md) {
          height: 44px;
        }
      }

      &.allina {
        width: auto;
        height: 20px;
        margin-right: 30px;
        @include media-breakpoint(min, md) {
          height: 30px;
          margin-right: 47px;
        }
      }

      &.bcidaho {
        width: auto;
        height: 45px;
        margin-right: 7px;
        @include media-breakpoint(min, md) {
          height: 69px;
          margin-right: 50px;
        }
      }
    }
  }

  .responsive-nav-menu {
    @media (max-width: 1200px) {
      display: none;
    }
  }

  .nav-link {
    display: block;
    color: $white;
    padding: 15px;

    &:focus,
    &:active,
    &:hover {
      color: $green;
    }
  }

  .menu-button img {
    width: 64px;
  }

  .apply-now {
    padding: 16px 24px;
  }
}

body.nav-open {
  @include media-breakpoint(max, md) {
    overflow: hidden;
  }
}

.navbar-menu {
  width: 100%;
  color: $white;
  font-size: 16px;
  background: #4a4a4a;
  padding: 50px 0;
  margin-top: 100px;
  overflow-y: auto;
  @include media-breakpoint(min, md) {
    margin-top: 140px;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 3;
  }

  &__header {
    margin-bottom: 70px;
  }

  a {
    color: $white;
  }

  &__link {
    color: $white;
    display: block;
    font-size: 28px;
    font-weight: 500;
    line-height: 1.3;

    &:nth-child(4n + 6) {
      margin-top: 20px;
    }
  }

  hr {
    width: 200px;
    border-color: $white;
    border-width: 2px;
    opacity: 0.35;
    margin-left: 0;
  }

  .side {
    @include media-breakpoint(max, md) {
      padding-top: 30px;

      .section {
        margin-bottom: 30px;
      }

      .button {
        width: 100%;
        text-align: center;
      }
    }
  }
}

// Navbar animations
.navbar-enter {
  opacity: 0;
  transform: translateY(-100%);
}
.navbar-enter-active {
  opacity: 1;
  transform: translateY(0);
  transition: opacity 200ms, transform 200ms;
}
.navbar-exit {
  opacity: 1;
}
.navbar-exit-active {
  opacity: 0;
  transform: translateY(-100%);
  transition: opacity 200ms, transform 200ms;
}


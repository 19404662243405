.faq-button {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
}

.faq-wrapper {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.faq-title {
  padding: 22px 18px 18px 10px;
}

.faq-box {
  position: relative;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: white;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
  overflow-y: auto;
  padding: 20px;
}

.faq-link {
  cursor: pointer;
  padding: 0.5em;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: $primary-default;
  font-family: $avenir;
}

.card-wrapper {
  color: $primary-default;
  font-family: $avenir;
  a {
    text-decoration: underline;
  }
  & + * {
    margin-top: 1.4em;
  }
}

.card-item {
  overflow: hidden;
  transition: max-height 0.3s cubic-bezier(1, 0, 1, 0);
  height: auto;
  max-height: 9999px;
}

.card-item.collapsed {
  max-height: 0;
  transition: max-height 0.35s cubic-bezier(0, 1, 0, 1);
}

.card-title {
  font-weight: 600;
  cursor: pointer;
  padding: 0.5em 1em;
  display: flex;
  justify-content: space-between;
  align-items: center;

  &::after {
    content: '';
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid currentColor;
  }

  &.open {
    &::after {
      content: '';
      border-top: 0;
      border-bottom: 5px solid;
    }
  }
}

.card-content {
  padding: 1em 1.5em;
}

.page-header {
  color: $white;
  background: $indigo;
  position: relative;
  padding-bottom: 180px;
  @include media-breakpoint(min, md) {
    padding-bottom: 70px;
  }

  .image-title-logo {
    max-width: 100%;
  }

  .alert-banner {
    background: #ec6738;
    margin-bottom: 30px;
    @include media-breakpoint(min, md) {
      margin-bottom: 70px;
    }

    a {
      display: block;
      color: $white;
      font-size: 16px;
      line-height: 1.5;
      padding: 13px 0;
      margin: 0;
    }
  }

  h1 {
    font-size: 36px;
    letter-spacing: -0.5px;
    line-height: 43px;
    font-family: $wavehaus;
    margin-bottom: 15px;
    @include media-breakpoint(min, md) {
      font-size: 80px;
      line-height: 1.1;
    }
  }

  hr {
    max-width: 200px;
    border-color: $white;
    border-width: 4px;
    margin: 0 0 30px;
    @include media-breakpoint(min, md) {
      border-width: 10px;
    }
  }

  p {
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 58px;
    @include media-breakpoint(min, md) {
      font-size: 24px;
      line-height: 34px;
    }
  }

  a {
    display: block;
    text-align: center;
    @include media-breakpoint(min, md) {
      display: inline-block;
    }
  }

  &__image {
    // max-width: 720px;
    position: absolute;
    // bottom: 0;
    // left: 56%;

    max-width: 284px;
    left: calc(50% - 142px);
    bottom: -142px;
    @include media-breakpoint(min, md) {
      max-width: 576px;
      left: calc(50% + 8.33333%);
      bottom: -140px;
    }
  }
}

.become-a-pal {
  .page-header {
    .header-content {
      padding: 200px 0 30px;
      @include media-breakpoint(min, md) {
        padding: 150px 0;
      }

      h1 {
        margin-bottom: 20px;
      }

      p {
        margin-bottom: 50px;
      }

      .apply-now {
        display: inline-block;
        @include media-breakpoint(max, md) {
          width: 100%;
          display: block;
        }
      }
    }

    &__image {
      bottom: -142px;
      @include media-breakpoint(min, md) {
        left: 54%;
        bottom: -50px;
      }
    }
  }

  .pal-stories {
    padding: 100px 0;

    .papa-head {
      margin: 80px auto 50px;
      @include media-breakpoint(min, md) {
        margin: 0 auto 50px;
      }
    }

    .section-headings {
      h3 {
        font-size: 24px;
        line-height: 34px;
        margin-bottom: 35px;
        @include media-breakpoint(min, md) {
          font-size: 35px;
        }
      }
    }

    h4,
    p {
      font-size: 20px;
    }
  }

  .benefits {
    padding: 60px 0;
    @include media-breakpoint(min, md) {
      padding: 80px 0 100px;
    }

    &--extra-spacing {
      @include media-breakpoint(max, md) {
        padding-top: 180px;
      }
    }

    .section-headings h3 {
      margin-bottom: 100px;
      @include media-breakpoint(max, md) {
        font-size: 26px;
        line-height: 36px;
        text-align: left;
        margin-bottom: 45px;
      }
    }

    &__item {
      margin-bottom: 45px;
      @include media-breakpoint(min, md) {
        margin-bottom: 100px;
      }

      img {
        margin-bottom: 45px;
        @include media-breakpoint(max, md) {
          max-width: 250px;
          margin-bottom: 30px;
        }
      }

      h4 {
        font-size: 20px;
        line-height: 26px;
        @include media-breakpoint(min, md) {
          font-size: 24px;
          line-height: 34px;
        }
      }

      p {
        max-width: 375px;
        font-size: 18px;
        line-height: 26px;
        @include media-breakpoint(min, md) {
          font-size: 20px;
          line-height: 26px;
        }
      }
    }

    .button {
      @include media-breakpoint(min, md) {
        margin-top: 80px;
      }
    }
  }

  .papa-pal-experiences {
    background: url("../../assets/papa-head-bg.svg");
    background-size: 60%;
    background-repeat: no-repeat;
    background-position: 0% 10%;

    hr {
      max-width: 300px;
      margin: 70px auto;
      @include media-breakpoint(min, md) {
        margin: 150px auto 80px;
      }
    }

    .section-headings {
      margin-bottom: 70px;
      @include media-breakpoint(min, md) {
        margin-bottom: 100px;
      }

      h4 {
        font-size: 24px;
        line-height: 34px;
        @include media-breakpoint(min, md) {
          font-size: 34px;
          line-height: 48px;
        }
      }
    }
  }

  .application-form {
    .box {
      background: #fff6da;
      border: 1px solid #f5eac8;
      border-radius: 6px;
      padding: 25px;
      margin-bottom: 30px;

      a {
        color: $blue;
        text-decoration: underline;
      }
    }

    &__content {
      border-top: 3px solid $slate;
      border-bottom: 3px solid $slate;
      padding: 50px 0;
      margin: 100px 0;

      h4 {
        font-size: 18px;
        line-height: 26px;
        @include media-breakpoint(min, md) {
          font-size: 24px;
          line-height: 34px;
        }
      }

      .form-control {
        height: 74px;
        padding: 18px 20px;
      }

      .form-feedback {
        text-align: left;
      }

      .button {
        display: inline-flex;
      }
    }
  }
}
